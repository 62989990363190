import type { ControllerParams } from '@wix/yoshi-flow-editor';

// slice
import * as onboarding from 'store/onboarding';

// types
import { type CompleteStepRequest } from '@wix/ambassador-groups-onboarding-v1-onboarding/types';
import type { IRootStore } from 'store/index';

export function OnboardingVM(params: ControllerParams, store: IRootStore) {
  return {
    onboarding$: {
      initialize,
      completeStep,
      closeOnboarding,
    },
  };

  function initialize(groupId: string) {
    return store.dispatch(onboarding.thunks.initialize(groupId));
  }

  function completeStep(params: CompleteStepRequest) {
    return store.dispatch(onboarding.thunks.completeStep(params));
  }

  function closeOnboarding(onboardingId: string) {
    return store.dispatch(onboarding.thunks.skipOnboarding(onboardingId));
  }
}

export type IOnboardingVM = ReturnType<typeof OnboardingVM>;

import { Role } from '@wix/ambassador-social-groups-v2-group-member-with-profile/types';
import { GroupRole } from '@wix/ambassador-socialgroups-v3-group-member/types';

const v2Role: { [key in GroupRole]: Role } = {
  [GroupRole.UNKNOWN_ROLE]: Role.UNKNOWN_ROLE,
  [GroupRole.MEMBER]: Role.MEMBER,
  [GroupRole.ADMIN]: Role.ADMIN,
};

export function toGroupRoleV2(role: GroupRole): Role {
  return v2Role[role];
}

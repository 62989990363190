import type { StateDeclarationFn } from 'controller/types';
import type { IGroup } from '@wix/social-groups-serverless/dist/groups/types';

import { GroupAppKey } from 'store/groups/types';
import { selectIsAppAvailable } from 'store/selectors';

export default (function (vm, store, params) {
  return [
    {
      abstract: true,
      name: 'group.events',
      url: '/events',
      redirectTo: 'group.events.list',
      data: {
        sectionId: 'group',
        title: 'groups-web.events',
        application: GroupAppKey.EVENTS_APP,
      },
      resolve: [
        {
          token: 'eventsAvailable',
          deps: ['group'],
          resolveFn(group: IGroup) {
            return selectIsAppAvailable(store.getState(), {
              groupId: group.id!,
              application: GroupAppKey.EVENTS_APP,
            });
          },
        },
        {
          token: 'events',
          deps: ['group', 'eventsAvailable'],
          resolveFn(group: IGroup, isEventsAvailable: boolean) {
            if (isEventsAvailable) {
              return vm.events$.fetch(group.id!);
            }
          },
        },
      ],
    },
    {
      name: 'group.events.list',
      url: '',
    },
    {
      name: 'group.events.create',
      url: '/create',
    },
    {
      name: 'group.events.edit',
      url: '/edit/:eventSlug',
    },
  ];
} as StateDeclarationFn);

import { createEntityAdapter } from '@reduxjs/toolkit';

import { ITopic } from '@wix/social-groups-serverless/dist/topics/types';
import { IRootState } from '../types';

export const topicsAdapter = createEntityAdapter<ITopic>({
  selectId: (topic) => topic.id as string,
  sortComparer: (a, b) => {
    if (a.displayOrder! < b.displayOrder!) {
      return -1;
    }

    if (a.displayOrder! > b.displayOrder!) {
      return 1;
    }

    return 0;
  },
});

export const selectors = topicsAdapter.getSelectors<IRootState>(
  (state) => state.topics,
);

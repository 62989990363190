import type { StateDeclarationFn } from 'controller/types';

export default (function (vm) {
  return {
    name: 'my-groups',
    parent: 'tpa',
    url: '',
    abstract: true,
    params: {
      memberSlug: {
        squash: true,
        value: null,
      },
    },
    data: {
      title: 'my-groups',
      sectionId: 'my-groups',
    },
    resolve: [
      {
        token: 'user',
        async resolveFn() {
          return vm.application$.fetchUserProfile().unwrap();
        },
      },
    ],
  };
} as StateDeclarationFn);

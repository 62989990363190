import type { StateDeclarationFn } from './types';

export default (function () {
  return [
    {
      parent: 'tpa',
      abstract: true,
      name: 'social-groups',
      url: '?social-groups-ooi-override',
    },
  ];
} as StateDeclarationFn);

import { createEntityAdapter } from '@reduxjs/toolkit';

import type { IRootState } from 'store/types';

import type { IToast } from './types';

export const toastsAdapter = createEntityAdapter<IToast>({
  selectId: (toast) => toast.id,
});

export const selectors = toastsAdapter.getSelectors<IRootState>(
  (state) => state.application.toasts,
);

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import type { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import * as application from './application';
import * as groups from './groups';
import * as media from './media';
import * as files from './files';
import * as feed from './feed';
import * as topics from './topics';
import * as events from './events';
import * as members from './members';
import * as search from './search';
import * as groupRequests from './group-requests';
import * as onboarding from './onboarding';

const rootReducer = combineReducers({
  application: application.reducer,
  groups: groups.reducer,
  media: media.reducer,
  files: files.reducer,
  feed: feed.reducer,
  topics: topics.reducer,
  events: events.reducer,
  members: members.reducer,
  search: search.reducer,
  groupRequests: groupRequests.reducer,
  onboarding: onboarding.reducer,
});

export function createStore(flowApi: ControllerFlowAPI, preloadedState?: any) {
  return configureStore({
    preloadedState,
    devTools: false,
    reducer: rootReducer,
    middleware(getDefaultMiddleware) {
      return getDefaultMiddleware({
        serializableCheck: false,
        thunk: {
          extraArgument: flowApi,
        },
      }).concat(onboarding.listener.middleware);
    },
  });
}

export type IRootStore = ReturnType<typeof createStore>;
export type IRootState = ReturnType<typeof rootReducer>;
export type IRootStoreDispatch = IRootStore['dispatch'];

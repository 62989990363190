import type { ControllerParams } from '@wix/yoshi-flow-editor';

import * as search from 'store/search';
import type { ISearchParams } from 'store/search/types';

import type { IRootStore } from 'store/index';
import { ISearchCentralFeedParams } from 'store/feed';

export function SearchVM(params: ControllerParams, store: IRootStore) {
  return {
    search$: {
      query,
      queryCentralFeed,
    },
  };

  function query(params: ISearchParams) {
    return store.dispatch(search.thunks.query(params));
  }

  function queryCentralFeed(params: ISearchCentralFeedParams) {
    return store.dispatch(search.thunks.queryCentralFeed(params));
  }
}

export type ISearchVM = ReturnType<typeof SearchVM>;

import * as list from './list';
import * as item from './item';
import * as feed from './feed';

export const styles = {
  ...item.styles,
  ...list.styles,
  ...feed.styles,
};

export const settings = {
  ...item.settings,
  ...list.settings,
  ...feed.settings,
};

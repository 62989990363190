import {
  createSettingsParam,
  createStylesParam,
  SettingsParamType,
  StyleParamType,
  wixColorParam,
  wixFontParam,
} from '@wix/tpa-settings';

import { EAlignment } from '../constants';
import { KeyTransform } from '../helpers';

export const styles = {
  // use in list/grid layout
  feedVerticalSpacing: createStylesParam('feedVerticalSpacing', {
    getDefaultValue: () => 24,
    type: StyleParamType.Number,
  }),
  feedTitleFont: createStylesParam('feedTitleFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Title', {
      size: 20,
      htmlTag: 'h1',
    }),
  }),
  feedSubtitleFont: createStylesParam('feedSubtitleFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
      htmlTag: 'p',
    }),
  }),
  feedTitleColor: createStylesParam('feedTitleColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  feedSubtitleColor: createStylesParam('feedSubtitleColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),

  showFeedTitle: createStylesParam('showFeedTitle', {
    getDefaultValue: () => true,
    type: StyleParamType.Boolean,
  }),

  showFeedSubtitle: createStylesParam('showFeedSubtitle', {
    getDefaultValue: () => true,
    type: StyleParamType.Boolean,
  }),

  showCreatePost: createStylesParam('showCreatePost', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),

  feedAlignment: createStylesParam('feedAlignment', {
    type: StyleParamType.Number,
    getDefaultValue: () => EAlignment.Center,
  }),
};

export const settings = {
  feedTitle: createSettingsParam('feedTitle', {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) =>
      t('groups-web.settings.TextTab.feedTitle.default'),
  }),

  feedSubtitle: createSettingsParam('feedSubtitle', {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) =>
      t('groups-web.settings.TextTab.feedSubtitle.default'),
  }),

  showFeed: createSettingsParam('showFeed', {
    getDefaultValue: () => false,
    type: SettingsParamType.Boolean,
    dangerousKeyTransformationOverride: KeyTransform.global,
  }),
};

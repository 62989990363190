import type { ControllerParams } from '@wix/yoshi-flow-editor';

import * as feed from 'store/feed';

import type { IRootStore } from 'store/index';

import type { ICommentsVM } from './comments.vm';

export function CentralFeedVM(
  params: ControllerParams,
  comments: ICommentsVM,
  store: IRootStore,
) {
  return {
    centralFeed$: {
      fetch,
      fetchByAuthor,
    },
  };

  async function fetchByAuthor(memberId?: string, offset?: number) {
    const { data } = await store
      .dispatch(
        feed.thunks.queryCentralFeed({
          fieldset: 'latestComments,reactions',
          query: {
            filter: {
              createdBy: {
                $eq: memberId,
              },
            },
            paging: {
              offset,
              limit: 10,
            },
          },
        }),
      )
      .unwrap();
    return data;
  }

  async function fetch(cursor?: string, withComments?: boolean) {
    const { data } = await store
      .dispatch(
        feed.thunks.fetchCentralFeed({
          cursor: {
            cursor,
            limit: 10,
          },
        }),
      )
      .unwrap();

    if (withComments) {
      await comments._.comments.fetch(data.items);
    }

    return data;
  }
}

export type ICentralFeedVM = ReturnType<typeof CentralFeedVM>;

import * as icons from './icons';
import * as text from './text';
import * as posts from './posts';

export const styles = {
  ...icons.styles,
  ...text.styles,
  ...posts.styles,
};

export const settings = {
  ...icons.settings,
  ...text.settings,
  ...posts.settings,
};

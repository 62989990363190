import _ from 'lodash';
import type { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  SortOrder,
  ContentType,
} from '@wix/ambassador-feed-v1-feed-item/types';
import {
  EmptyState,
  QueryFeedRequest,
} from '@wix/ambassador-social-groups-v2-central-feed-item/types';
import { getPricingPlansPublicAPI } from '@wix/pricing-plans-tpa-api';
import { RichContentMigration } from '@wix/social-groups-serverless/dist/constants';

import * as feedApi from '@wix/social-groups-serverless/dist/feed/http';
import * as centralFeedApi from '@wix/social-groups-serverless/dist/central-feed/http';
import { IFeedItem } from '@wix/social-groups-serverless/dist/feed/types';

import * as topics from '../topics';
import { createAsyncThunk, serializeHttpError } from '../utils';

import { selectors } from './selectors';

import type {
  ICreateParams,
  IFetchCentralFeedParams,
  IFetchParams,
  IGetParams,
  IPinParams,
  IReactParams,
  IRemoveParams,
  ISubscribeParams,
  IUnpinParams,
  IUnreactParams,
  IUnsubscribeParams,
  IUpdateParams,
  ISearchParams,
  ISearchCentralFeedParams,
  IViewParams,
} from './types';

export const search = createAsyncThunk(
  'feed:search',
  async function (params: ISearchParams, api) {
    const { groupId, query, paging } = params;

    const res = await api.extra.httpClient.request(
      feedApi.search({
        groupId,
        params: {
          query: {
            filter: {
              $or: [{ rawText: query }, { 'entity.title': query }],
            },
            paging: { limit: 10, ...paging },
            sort: [{ fieldName: 'createdAt', order: SortOrder.DESC }],
          },
        },
      }),
    );

    res.data.items = await withTranslatedPricingPlansDetails(
      res.data.items || [],
      api.extra,
    );
    return res;
  },
);

export const centralFeedSearch = createAsyncThunk(
  'feed:central-feed:search',
  async function (params: ISearchCentralFeedParams, api) {
    const { query, paging } = params;

    const res = await api.extra.httpClient.request(
      centralFeedApi.query({
        fieldset: 'latestComments,reactions',
        query: {
          filter: {
            $or: [{ rawText: query }, { 'entity.title': query }],
          },
          paging: { limit: 10, ...paging },
          sort: [{ fieldName: 'createdAt', order: SortOrder.DESC }],
        },
      }),
    );

    res.data.items = await withTranslatedPricingPlansDetails(
      res.data.items || [],
      api.extra,
    );
    return res;
  },
);

export const create = createAsyncThunk(
  'feed:create',
  async function (params: ICreateParams, thunkAPI) {
    const { groupId, content, contentType } = params;
    const { isViewer } = thunkAPI.extra.environment;

    const { authentication } = thunkAPI.extra.controllerConfig.wixCodeApi;

    const feedItem = !isViewer
      ? await request()
      : await authentication.withCaptchaChallengeHandler(request);

    if (feedItem.entity.topics.length) {
      thunkAPI.dispatch(
        topics.actions.incrementCounter(
          feedItem.entity.topics.map((topic) => topic.id as string),
        ),
      );
    }

    return feedItem;
    function request(captchaToken?: string | null) {
      return thunkAPI.extra.httpClient
        .request(
          feedApi.create({
            groupId,
            request: {
              captchaToken,
              entity: {
                topics: params.topics,
                title: params.title,
                body: {
                  content,
                  contentType,
                },
              },
            },
          }),
        )
        .then((response) => response.data)
        .catch((error) => Promise.reject(serializeHttpError(error)));
    }
  },
);

export const update = createAsyncThunk(
  'feed:update',
  async function (params: IUpdateParams, thunkAPI) {
    const { groupId, feedItemId, content, contentType } = params;
    const { isViewer } = thunkAPI.extra.environment;
    const { authentication } = thunkAPI.extra.controllerConfig.wixCodeApi;

    const old = selectors.selectById(thunkAPI.getState(), feedItemId);

    const feedItem = !isViewer
      ? await request()
      : await authentication.withCaptchaChallengeHandler(request);

    const removedTopics = old!.entity.topics.filter(
      ({ id }) => !feedItem.entity.topics.some((topic) => topic.id === id),
    );

    const addedTopics = feedItem.entity.topics.filter(
      (topic) => !old?.entity.topics.some(({ id }) => topic.id === id),
    );

    thunkAPI.dispatch(
      topics.actions.decrementCounter(
        removedTopics.map((topic) => topic.id as string),
      ),
    );

    thunkAPI.dispatch(
      topics.actions.incrementCounter(
        addedTopics.map((topic) => topic.id as string),
      ),
    );

    return feedItem;

    function request(captchaToken?: string | null) {
      return thunkAPI.extra.httpClient
        .request(
          feedApi.update({
            groupId,
            request: {
              feedItemId,
              captchaToken,
              entity: {
                topics: params.topics,
                title: params.title,
                body: {
                  content,
                  contentType,
                },
              },
            },
          }),
        )
        .then((response) => response.data)
        .catch((error) => Promise.reject(serializeHttpError(error)));
    }
  },
);

export const view = createAsyncThunk(
  'feed:view',
  async function (params: IViewParams, thunkAPI) {
    return thunkAPI.extra.httpClient
      .request(feedApi.view(params))
      .then((response) => response.data);
  },
  { hideErrorMessage: true },
);

export const subscribe = createAsyncThunk(
  'feed:subscribe',
  async function (params: ISubscribeParams, thunkAPI) {
    return thunkAPI.extra.httpClient.request(feedApi.subscribe(params));
  },
);
export const unsubscribe = createAsyncThunk(
  'feed:unsubscribe',
  async function (params: IUnsubscribeParams, thunkAPI) {
    return thunkAPI.extra.httpClient.request(feedApi.unsubscribe(params));
  },
);

export const pin = createAsyncThunk(
  'feed:pin',
  async function (params: IPinParams, thunkAPI) {
    return thunkAPI.extra.httpClient.request(feedApi.pin(params));
  },
);

export const unpin = createAsyncThunk(
  'feed:unpin',
  async function (params: IUnpinParams, thunkAPI) {
    return thunkAPI.extra.httpClient.request(feedApi.unpin(params));
  },
);

export const react = createAsyncThunk(
  'feed:react',
  async function (params: IReactParams, thunkAPI) {
    const { groupId, code, feedItemId, mode } = params;

    return thunkAPI.extra.httpClient.request(
      feedApi.react({
        groupId,
        resourceId: feedItemId,
        reactionCode: code,
        mode,
      }),
    );
  },
  {
    formatError: (params) => ({
      i18nKey: 'groups-web.toast.error.feed.react',
      values: {
        code: params.code,
      },
    }),
  },
);

export const unreact = createAsyncThunk(
  'feed:unreact',
  async function (params: IUnreactParams, thunkAPI) {
    const { groupId, code, feedItemId } = params;

    return thunkAPI.extra.httpClient.request(
      feedApi.unreact({ groupId, resourceId: feedItemId, reactionCode: code }),
    );
  },
  {
    formatError: (params) => ({
      i18nKey: 'groups-web.toast.error.feed.unreact',
      values: {
        code: params.code,
      },
    }),
  },
);

export const remove = createAsyncThunk(
  'feed:remove',
  async function (params: IRemoveParams, thunkAPI) {
    const { feedItemId } = params;

    const state = thunkAPI.getState();
    const feedItem = selectors.selectById(state, feedItemId);

    if (feedItem?.entity.topics.length) {
      thunkAPI.dispatch(
        topics.actions.decrementCounter(
          feedItem?.entity.topics.map((topic) => topic.id as string),
        ),
      );
    }

    return thunkAPI.extra.httpClient.request(feedApi.remove(params));
  },
);

export const get = createAsyncThunk(
  'feed:get',
  async function (params: IGetParams, thunkAPI) {
    const { data } = await thunkAPI.extra.httpClient.request(
      feedApi.get(params),
    );

    data.item = (
      await withTranslatedPricingPlansDetails([data.item], thunkAPI.extra)
    )[0];
    return data.item;
  },
);

export const fetch = createAsyncThunk(
  'feed:fetch',
  async function (params: IFetchParams, thunkAPI) {
    const { groupId, query, cursor } = params;
    const contentType =
      (await thunkAPI.extra.experiments.get(RichContentMigration.GroupFeed)) ||
      'DRAFTJS';

    const res = await thunkAPI.extra.httpClient.request(
      feedApi.fetch({
        groupId,
        params: {
          cursor,
          contentType,
          query,
        },
      }),
    );

    res.data.items = await withTranslatedPricingPlansDetails(
      res.data.items!,
      thunkAPI.extra,
    );
    return res;
  },
);

export const queryCentralFeed = createAsyncThunk(
  'feed:central-feed:query',
  async function (params: QueryFeedRequest, thunkAPI) {
    const { httpClient } = thunkAPI.extra;
    const contentType =
      (await thunkAPI.extra.experiments.get(
        RichContentMigration.CentralFeed,
      )) || 'DRAFTJS';

    const res = await httpClient.request(
      centralFeedApi.query({
        ...params,
        contentType,
      }),
    );

    res.data.items = await withTranslatedPricingPlansDetails(
      res.data.items,
      thunkAPI.extra,
    );
    return res;
  },
  {
    formatError: () => ({ i18nKey: 'groups-web.toast.error.my-feed.fetch' }),
  },
);

export const fetchCentralFeed = createAsyncThunk(
  'feed:central-feed:fetch',
  async function (params: IFetchCentralFeedParams, thunkAPI) {
    const { httpClient } = thunkAPI.extra;
    const contentType =
      (await thunkAPI.extra.experiments.get(
        RichContentMigration.CentralFeed,
      )) || 'DRAFTJS';

    const res = await httpClient.request(
      centralFeedApi.fetch({
        emptyState: EmptyState.PUBLIC_POSTS,
        limit: params.cursor?.limit!,
        contentType,
        cursor: params.cursor?.cursor,
      }),
    );

    res.data.items = await withTranslatedPricingPlansDetails(
      res.data.items,
      thunkAPI.extra,
    );
    return res;
  },
  {
    formatError: () => ({ i18nKey: 'groups-web.toast.error.feed.fetch' }),
  },
);

async function withTranslatedPricingPlansDetails(
  feedItems: IFeedItem[],
  flowAPI: ControllerFlowAPI,
) {
  const { wixCodeApi } = flowAPI.controllerConfig;

  const hasPaidPlans = feedItems.some(
    (feedItem) => feedItem.accessRestriction?.paidPlansOptions?.planIds?.length,
  );

  if (!hasPaidPlans) {
    return feedItems;
  }

  try {
    const api = await getPricingPlansPublicAPI(wixCodeApi);

    return feedItems.map((feedItem) => {
      const plans = feedItem.accessRestriction?.paidPlansOptions?.plans ?? [];

      if (!plans.length) {
        return feedItem;
      }

      return _.merge({}, feedItem, {
        accessRestriction: {
          paidPlansOptions: {
            plans: plans.map((plan) => ({
              ...plan,
              translatedDetails: api.getTranslatedPricingDetails(plan),
            })),
          },
        },
      });
    });
  } catch {
    return feedItems;
  }
}

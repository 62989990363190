import type { UIRouter } from '@wix/tpa-router';
import type { ControllerParams } from '@wix/yoshi-flow-editor';
import { createEventHandler } from '@wix/tpa-settings';

import { TpaSettingsPanelEvent } from './constants';

/**
 * Helper for tpa-settings
 */
export function initializeSettingsEventHandler(
  router: UIRouter,
  params: ControllerParams,
) {
  const { config } = params.controllerConfig;

  const handler = createEventHandler<typeof TpaSettingsPanelEvent>(
    config.publicData.COMPONENT,
  );

  handler.on('RouteChange', function (state: string) {
    router.stateService.go(state);
  });

  return handler;
}

export const GROUPS_APP_DEFINITION_ID = '148c2287-c669-d849-d153-463c7486a694';
export const MEMBERS_APP_DEFINITION_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9';
export const STORES_APP_DEFINITION_ID = '215238eb-22a5-4c36-9e7b-e7c08025e04e';
export const BOOKINGS_APP_DEFINITION_ID =
  '13d21c63-b5ec-5912-8397-c3a5ddb27a97';
export const EVENTS_APP_DEFINITION_ID = '140603ad-af8d-84a5-2c80-a0f60cb47351';
export const PAID_PLANS_APP_DEFINITION_ID =
  '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3';

export const INVITE_PAID_PLANS = 'invite_paid_plans';
export const GROUPS_DASHBOARD_URL = 'wix-groups';

import {
  createStylesParam,
  StyleParamType,
  wixColorParam,
} from '@wix/tpa-settings';

export const settings = {};

export const styles = {
  postIconsColor: createStylesParam('postIconsColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
};

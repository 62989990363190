import type { ControllerParams } from '@wix/yoshi-flow-editor';

import { createStore, type IRootStore } from '../store';

/**
 * Self explanatory
 */
export function initializeStore(params: ControllerParams): IRootStore {
  const { compId } = params.controllerConfig;
  const { wixCodeApi } = params.flowAPI.controllerConfig;
  const { isSSR } = params.flowAPI.environment;
  const { warmupData } = wixCodeApi.window;

  return createStore(
    params.flowAPI,
    isSSR ? undefined : warmupData.get(`${compId}-store`),
  );
}

/**
 * saves store state to warmup data in SSR stage
 */
export function persistStore(store: IRootStore, params: ControllerParams) {
  const { compId } = params.controllerConfig;
  const { wixCodeApi } = params.flowAPI.controllerConfig;
  const { isSSR } = params.flowAPI.environment;
  const { warmupData } = wixCodeApi.window;

  if (isSSR) {
    warmupData.set(`${compId}-store`, store.getState());
  }
}

import type { StateDeclarationFn } from 'controller/types';

export default [
  require('./about-page.route').default,
  require('./custom-page.route').default,
  require('./discussion-page.route').default,
  require('./events-page.route').default,
  require('./feed-page.route').default,
  require('./files-page.route').default,
  require('./group-page.route').default,
  require('./media-page.route').default,
  require('./members-page.route').default,
  require('./post-page.route').default,
] as StateDeclarationFn[];

import type { UIRouter } from '@wix/tpa-router';
import type { ControllerParams } from '@wix/yoshi-flow-editor';

export function configureRouter(router: UIRouter, params: ControllerParams) {
  router.urlService.rules.initial({
    state: 'my-groups.groups',
  });
  router.urlService.rules.otherwise({
    state: 'my-groups.groups',
  });
}

import type { IRootState } from '../types';
import { createSelector } from 'reselect';
import { selectors } from './adapter';

export const selectOnboarding = createSelector(
  (state: IRootState) => state.onboarding,
  (onboarding) => ({
    data: {
      steps: selectors.selectAll(onboarding.data.steps),
      id: onboarding.data.id,
      status: onboarding.data.status,
    },
    meta: {
      error: onboarding.error,
      loading: onboarding.loading,
    },
  }),
);

export type IOnboardingSelector = ReturnType<typeof selectOnboarding>;

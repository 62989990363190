import type { StateDeclarationFn } from 'controller/types';
import type { IApplicationUser } from 'store/application';

export default (function (vm) {
  return {
    name: 'my-groups.posts',
    url: '/posts',
    data: {
      title: 'my-groups.posts',
      sectionId: 'my-groups',
    },
    resolve: [
      {
        token: 'feed',
        deps: ['user'],
        resolveFn(user: IApplicationUser) {
          if (user.memberId) {
            return vm.centralFeed$.fetchByAuthor(user.memberId);
          }
        },
      },
    ],
  };
} as StateDeclarationFn);

import { search as feedSearch, centralFeedSearch } from 'store/feed/thunks';

import { ISearchCentralFeedParams } from 'store/feed/types';

import { createAsyncThunk } from '../utils';

import type { ISearchParams } from './types';

/**
 * Temporary search implementation
 * Currently done by dispatching the feed search
 */
export const query = createAsyncThunk(
  'search:query',
  async function (params: ISearchParams, api) {
    return api.dispatch(feedSearch(params));
  },
);

export const queryCentralFeed = createAsyncThunk(
  'search:query-central-feed',
  async function (params: ISearchCentralFeedParams, api) {
    return api.dispatch(centralFeedSearch(params));
  },
);

import _ from 'lodash';
import type { RawParams, ResolveTypes, Transition } from '@wix/tpa-router';

import { getGroupIdBySlug } from '@wix/ambassador-social-groups-v2-group/http';
import { type GetGroupIdBySlugResponse } from '@wix/ambassador-social-groups-v2-group/types';

import type { IGroup } from '@wix/social-groups-serverless/dist/groups/types';
import type { IApplicationUser } from 'store/application/types';
import {
  selectGroupBySlug,
  selectHasAdminRole,
  selectIsJoinedGroupMember,
} from 'store/selectors';

import type { StateDeclarationFn } from 'controller/types';
import { isOptimizationEnabled } from 'controller/ssr-optimization';

export default (function (vm, store, params) {
  const { httpClient } = params.flowAPI;

  const optimization = isOptimizationEnabled(params);

  return {
    name: 'group',
    parent: 'social-groups',
    url: '/:slug?autoInviteId&invite&appSectionParams',
    params: {
      slug: {
        value: null,
        squash: true,
      },
      appSectionParams: {
        dynamic: true,
        type: 'json',
      },
      invite: {
        type: 'bool',
        value: false,
        squash: true,
        dynamic: true,
      },
      autoInviteId: {
        squash: true,
        dynamic: true,
      },
    },
    data: { sectionId: 'group' },
    resolve: _.compact<ResolveTypes>([
      {
        token: 'user',
        async resolveFn() {
          return vm.application$.fetchUserProfile().unwrap();
        },
      },
      {
        token: 'isLoggedIn',
        deps: ['user'],
        resolveFn(user: IApplicationUser) {
          return user.loggedIn;
        },
      },
      {
        token: 'group',
        deps: ['$stateParams', '$transition$'],
        async resolveFn(params: RawParams, transition: Transition) {
          const { custom } = transition.options();

          if (custom?.retain) {
            return selectGroupBySlug(store.getState(), params.slug);
          }

          const data = await vm.group$
            .fetch(params.slug, params.autoInviteId)
            .unwrap();

          return data.group;
        },
      },
      optimization && {
        token: 'partialGroup',
        deps: ['$stateParams'],
        resolveFn(params: RawParams) {
          return httpClient
            .request(
              getGroupIdBySlug({
                slug: params.slug,
                autoInviteId: params.autoInviteId,
              }),
            )
            .then((response) => response.data);
        },
      },
      optimization && {
        token: 'groupId',
        deps: ['partialGroup'],
        resolveFn(response: GetGroupIdBySlugResponse) {
          return response.groupId;
        },
      },
      !optimization && {
        token: 'groupId',
        deps: ['group'],
        resolveFn(group: IGroup) {
          return group.id;
        },
      },
      {
        token: 'isAdmin',
        deps: ['group'],
        resolveFn(group: IGroup) {
          return selectHasAdminRole(store.getState(), group.id!);
        },
      },
      {
        token: 'isJoined',
        deps: ['group'],
        resolveFn(group: IGroup) {
          return selectIsJoinedGroupMember(store.getState(), group.id!);
        },
      },
    ]),
  };
} as StateDeclarationFn);

export const enum EAlignment {
  Left = 1,
  Right = 2,
  Center = 3,
}

export const enum EImageRatio {
  Rectangle = 1,
  Square = 2,
}

export const enum EImageCrop {
  Crop = 1,
  Fit = 2,
}

export const enum EImageLayout {
  RTL = 1,
  LTR = 2,
  Mixed = 3,
}

export const enum ECoverImageLayout {
  Thumbnail = 1,
  Large = 2,
  Blank = 3,
}

export const enum EMediaLayout {
  Grid = 1,
  Masonry = 2,
  Collage = 3,
}

export enum ELayoutType {
  Grid = 'grid',
  List = 'list',
  ListWidget = 'listWidget',
  SideBySide = 'sideBySide',
  Sidebar = 'sideBar',
}

export enum EGroupListSort {
  Members = 'members_count',
  Activity = 'recent_activity_date',
  Name = 'title',
}

/**
 * This is a hack that allows you NOT to think about
 * where to import settingsParams/stylesParams from
 * (especially when components have different default values)
 *
 * Every component must define own COMPONENT.name,
 * then in settings/* we can do a switch/case for shared settingsParams/stylesParams
 * in getDefaultValue function based on COMPONENT.name
 *
 */
export const COMPONENT: {
  name?:
    | 'GroupsPage'
    | 'SideBySideWidget'
    | 'GroupListWidget'
    | 'GroupPage'
    | 'FeedWidget';
} = {
  name: undefined,
};

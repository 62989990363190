import * as media from '@wix/social-groups-serverless/dist/media/http';

import { createAsyncThunk } from '../utils';

export const fetch = createAsyncThunk(
  'media:fetch',
  async function (params: { groupId: string; isMobile: boolean }, thunkAPI) {
    const response = await thunkAPI.extra.httpClient.request(
      media.fetch(params),
    );

    return response.data;
  },
);

import { createEntityAdapter } from '@reduxjs/toolkit';

import { IRootState } from '../types';

import { IGroup } from './types';

export const groupsAdapter = createEntityAdapter<IGroup>({
  selectId: (item) => item.id as string,
});

export const selectors = groupsAdapter.getSelectors<IRootState>(
  (state) => state.groups,
);

import { createSelector } from 'reselect';
import { curryRight } from 'lodash';

import type { ITopic } from '@wix/social-groups-serverless/dist/topics/types';

import type { IRootState } from '../types';

import { selectors } from './adapter';

export const selectAllTopics = (state: IRootState) =>
  selectors.selectAll(state);

export const selectLastCreatedTopic = createSelector(
  (state: IRootState) => state,
  (state) =>
    selectors.selectById(state, state.topics.statuses.create.itemId as string),
);

export const selectTopic = curryRight(
  createSelector(
    [(state: IRootState) => state, (_, id: string) => id],
    (state, id) => selectors.selectById(state, id),
  ),
  2,
);

export const selectTopicsState = createSelector(
  (state: IRootState) => state.topics,
  (state) => state,
);

export const selectTopicsStatus = (state: IRootState) => state.topics.statuses;

export const selectTopicsByIds = curryRight(
  createSelector(
    [(state: IRootState) => state, (_, ids: string[]) => ids],
    (state, ids) =>
      ids
        .map((id) => selectors.selectById(state, id) as ITopic)
        .filter(Boolean),
  ),
  2,
);

export const filterTopics = curryRight(
  createSelector(
    [(state: IRootState) => state, (_, name: string) => name],
    (state, name) => {
      return selectors
        .selectAll(state)
        .filter((topic) =>
          topic.displayName?.toLowerCase().includes(name.toLowerCase()),
        );
    },
  ),
  2,
);

import { createSlice } from '@reduxjs/toolkit';

import { centralFeedSearch, search } from 'store/feed/thunks';

import { adapter as feedItemsAdapter } from './feed';

export const searchSlice = createSlice({
  name: 'search',
  initialState: {
    feed: feedItemsAdapter.getInitialState({
      total: undefined as number | undefined,
      // has group's feed more items to fetch
      hasNext: undefined as boolean | undefined,
      // central feed cursor
      nextCursor: undefined as string | number | undefined,
      statuses: {
        fetch: {
          error: false,
          pending: false,
        },
        fetchMore: {
          error: false,
          pending: false,
        },
      },
    }),
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(search.pending, (state, action) => {
        const { paging } = action.meta.arg;

        if (paging?.offset) {
          state.feed.statuses.fetchMore.error = false;
          state.feed.statuses.fetchMore.pending = true;
        } else {
          feedItemsAdapter.removeAll(state.feed);
          state.feed.statuses.fetch.error = false;
          state.feed.statuses.fetch.pending = true;
        }
      })
      .addCase(search.rejected, (state, action) => {
        const { paging } = action.meta.arg;

        if (paging?.offset) {
          state.feed.statuses.fetchMore.error = true;
          state.feed.statuses.fetchMore.pending = false;
        } else {
          state.feed.statuses.fetch.error = true;
          state.feed.statuses.fetch.pending = false;
        }
      })
      .addCase(search.fulfilled, (state, action) => {
        const { data } = action.payload;
        const { paging } = action.meta.arg;

        state.feed.total = data.total!;
        state.feed.hasNext = data.hasNext!;

        if (paging?.offset) {
          feedItemsAdapter.addMany(state.feed, data.items ?? []);
          state.feed.statuses.fetchMore = {
            error: false,
            pending: false,
          };
        } else {
          feedItemsAdapter.setAll(state.feed, data.items ?? []);
          state.feed.statuses.fetch = {
            error: false,
            pending: false,
          };
        }
      });

    builder
      .addCase(centralFeedSearch.pending, (state, action) => {
        const { paging } = action.meta.arg;

        if (paging?.offset) {
          state.feed.statuses.fetchMore.error = false;
          state.feed.statuses.fetchMore.pending = true;
        } else {
          feedItemsAdapter.removeAll(state.feed);
          state.feed.statuses.fetch.error = false;
          state.feed.statuses.fetch.pending = true;
        }
      })
      .addCase(centralFeedSearch.rejected, (state, action) => {
        const { paging } = action.meta.arg;

        if (paging?.offset) {
          state.feed.statuses.fetchMore.error = true;
          state.feed.statuses.fetchMore.pending = false;
        } else {
          state.feed.statuses.fetch.error = true;
          state.feed.statuses.fetch.pending = false;
        }
      })
      .addCase(centralFeedSearch.fulfilled, (state, action) => {
        const { data } = action.payload;
        const { paging } = action.meta.arg;

        state.feed.total = data.total!;
        state.feed.nextCursor = data.nextCursor!;

        if (paging?.offset) {
          feedItemsAdapter.addMany(state.feed, data.items ?? []);
          state.feed.statuses.fetchMore = {
            error: false,
            pending: false,
          };
        } else {
          feedItemsAdapter.setAll(state.feed, data.items ?? []);
          state.feed.statuses.fetch = {
            error: false,
            pending: false,
          };
        }
      });
  },
});

import type { ControllerParams } from '@wix/yoshi-flow-editor';

import * as media from 'store/media';
import type { IRootStore } from 'store/index';

export function MediaVM(params: ControllerParams, store: IRootStore) {
  return {
    media$: {
      fetch,
    },
  };

  function fetch(groupId: string, isMobile: boolean) {
    return store.dispatch(media.thunks.fetch({ groupId, isMobile }));
  }
}

export type IMediaVM = ReturnType<typeof MediaVM>;

import {
  createStylesParam,
  StyleParamType,
  wixColorParam,
  wixFontParam,
} from '@wix/tpa-settings';

export const settings = {};

export const styles = {
  groupNameColor: createStylesParam('groupNameColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  groupNameFont: createStylesParam('groupNameFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-XL', { size: 20 }),
  }),
  sidebarGroupNameColor: createStylesParam('sidebarGroupNameColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  sidebarGroupNameFont: createStylesParam('sidebarGroupNameFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  }),
  groupNameColorOnHover: createStylesParam('groupNameColorOnHover', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  }),
  groupInfoColor: createStylesParam('groupInfoColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  groupInfoFont: createStylesParam('groupInfoFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 14 }),
  }),
  groupCardGridTopBottomSpacing: createStylesParam(
    'groupCardGridTopBottomSpacing',
    {
      getDefaultValue: () => 20,
      type: StyleParamType.Number,
    },
  ),
  groupCardGridSideSpacing: createStylesParam('groupCardGridSideSpacing', {
    getDefaultValue: () => 20,
    type: StyleParamType.Number,
  }),
  groupCardListTopBottomSpacing: createStylesParam(
    'groupCardListTopBottomSpacing',
    {
      getDefaultValue: () => 20,
      type: StyleParamType.Number,
    },
  ),
  groupCardListSideSpacing: createStylesParam('groupCardListSideSpacing', {
    getDefaultValue: ({ isMobile }) => (isMobile ? 0 : 20),
    type: StyleParamType.Number,
  }),
  groupCardListWidgetTopBottomSpacing: createStylesParam(
    'groupCardListWidgetTopBottomSpacing',
    {
      getDefaultValue: () => 20,
      type: StyleParamType.Number,
    },
  ),
  groupCardListWidgetSideSpacing: createStylesParam(
    'groupCardListWidgetSideSpacing',
    {
      getDefaultValue: () => 0,
      type: StyleParamType.Number,
    },
  ),
  cardSideBySideHeight: createStylesParam('cardSideBySideHeight', {
    getDefaultValue: () => 241,
    type: StyleParamType.Number,
  }),
  showMemberCount: createStylesParam('showMemberCount', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showAdminBadge: createStylesParam('showAdminBadge', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showGroupImage: createStylesParam('showGroupImage', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
};

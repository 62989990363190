import _ from 'lodash';
import { type CustomCssVarsFn } from '@wix/yoshi-flow-editor';

/**
 * omits undefined values
 * CustomCssVarsFn treats undefined as a value and injects it as a string
 */
export function vars(
  fn: (
    params: Parameters<CustomCssVarsFn>[0],
  ) => Record<string, string | boolean | number | undefined>,
): CustomCssVarsFn {
  return (params) =>
    _.omitBy(fn(params), _.isUndefined) as Record<
      string,
      string | boolean | number
    >;
}

export const KeyTransform = {
  /**
   * Marks setting/style as a global.
   * So value is always the same for all the breakpoints
   */
  global(key: string) {
    return key;
  },
};

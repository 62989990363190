import type { ControllerParams } from '@wix/yoshi-flow-editor';

import * as files from 'store/files';
import { IRootStore } from 'store/index';

export function FilesVM(params: ControllerParams, store: IRootStore) {
  return {
    files$: {
      fetch,
    },
  };

  function fetch(groupId: string) {
    return store.dispatch(files.thunks.fetch(groupId));
  }
}

export type IFilesVM = ReturnType<typeof FilesVM>;

import { createSelector } from 'reselect';

import type { IRootState } from '../types';

import { selectors } from './adapter';

export const selectFilesItems = createSelector(
  (state: IRootState) => state.files,
  (state) => ({
    items: selectors.selectAll(state),
    loading: state.loading,
    error: state.error,
  }),
);

import { createListenerMiddleware, isAnyOf, Store } from '@reduxjs/toolkit';
import {
  StepKey,
  OnboardingStatus,
  Status as StepStatus,
} from '@wix/ambassador-groups-onboarding-v1-onboarding/types';

import { thunks as feedThunks } from '../feed';
import { thunks as membersThunks } from '../members';
import { completeStep } from './thunks';
import { selectOnboarding, IOnboardingSelector } from './selectors';
import { selectGroupCoverImage } from 'store/selectors';

import type { IRootState } from '../types';

export const listener = createListenerMiddleware<
  IRootState,
  Store['dispatch']
>();

const stopCreatePostListener = listener.startListening({
  actionCreator: feedThunks.create.fulfilled,
  effect: async (action, listenerApi) => {
    const onboarding = selectOnboarding(listenerApi.getState());

    const shouldComplete = getCompletionReadyStatus({
      onboarding,
      stepKey: StepKey.CREATE_POST,
    });

    if (shouldComplete) {
      listenerApi.dispatch(
        completeStep({
          onboardingId: onboarding.data.id,
          stepKey: StepKey.CREATE_POST,
        }),
      );
    } else {
      stopCreatePostListener();
    }
  },
});

const stopReactToPostListener = listener.startListening({
  actionCreator: feedThunks.react.fulfilled,
  effect: async (action, listenerApi) => {
    const onboarding = selectOnboarding(listenerApi.getState());

    const shouldComplete = getCompletionReadyStatus({
      onboarding,
      stepKey: StepKey.REACT_TO_POST,
    });

    if (shouldComplete) {
      listenerApi.dispatch(
        completeStep({
          onboardingId: onboarding.data.id,
          stepKey: StepKey.REACT_TO_POST,
        }),
      );
    } else {
      stopReactToPostListener();
    }
  },
});

const stopInviteMembersListener = listener.startListening({
  matcher: isAnyOf(
    membersThunks.inviteByEmail.fulfilled,
    membersThunks.addToGroup.fulfilled,
  ),
  effect: async (action, listenerApi) => {
    const onboarding = selectOnboarding(listenerApi.getState());

    const shouldComplete = getCompletionReadyStatus({
      onboarding,
      stepKey: StepKey.INVITE_MEMBERS,
    });

    if (shouldComplete) {
      listenerApi.dispatch(
        completeStep({
          onboardingId: onboarding.data.id,
          stepKey: StepKey.INVITE_MEMBERS,
        }),
      );
    } else {
      stopInviteMembersListener();
    }
  },
});

const stopUpdateCoverImageListener = listener.startListening({
  predicate: (action, currentState, previousState) => {
    const onboarding = selectOnboarding(currentState);
    const prevCoverImage = selectGroupCoverImage(
      previousState,
      onboarding.data.id,
    );
    const nextCoverImage = selectGroupCoverImage(
      currentState,
      onboarding.data.id,
    );

    return prevCoverImage?.image?.mediaId !== nextCoverImage?.image?.mediaId;
  },
  effect: async (action, listenerApi) => {
    const onboarding = selectOnboarding(listenerApi.getState());

    const shouldComplete = getCompletionReadyStatus({
      onboarding,
      stepKey: StepKey.UPDATE_COVER_IMAGE,
    });

    if (shouldComplete) {
      listenerApi.dispatch(
        completeStep({
          onboardingId: onboarding.data.id,
          stepKey: StepKey.UPDATE_COVER_IMAGE,
        }),
      );
    } else {
      stopUpdateCoverImageListener();
    }
  },
});

function getCompletionReadyStatus({
  onboarding: {
    data: { status: onboardingStatus, steps },
  },
  stepKey,
}: {
  onboarding: IOnboardingSelector;
  stepKey: StepKey;
}) {
  const isOnboardingReady = onboardingStatus === OnboardingStatus.READY;
  const isStepActive =
    steps.find(({ stepKey: key }) => key === stepKey)?.status ===
    StepStatus.ACTIVE;
  return isOnboardingReady && isStepActive;
}

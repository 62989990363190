import { ControllerParams } from '@wix/yoshi-flow-editor';

import type { Group } from '@wix/ambassador-social-groups-v2-group/types';
import * as requests from 'store/group-requests';
import type { IDeclineParams, IFetchParams } from 'store/group-requests/types';
import type { IRootStore } from 'store/index';

export function GroupRequestsVM(params: ControllerParams, store: IRootStore) {
  return {
    groupRequests$: {
      fetch,
      approve,
      decline,
      cancel,
      create,
    },
  };

  function fetch(params: IFetchParams) {
    return store.dispatch(requests.thunks.fetch(params));
  }

  function approve(id: string) {
    return store.dispatch(requests.thunks.approve(id));
  }

  function decline(params: IDeclineParams) {
    return store.dispatch(requests.thunks.decline(params));
  }

  function cancel(id: string) {
    return store.dispatch(requests.thunks.cancel(id));
  }

  function create(group: Group) {
    return store.dispatch(requests.thunks.create(group));
  }
}

export type IGroupRequestsVM = ReturnType<typeof GroupRequestsVM>;

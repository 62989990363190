import { createSlice } from '@reduxjs/toolkit';

import { fetch, create, cancel, update } from './thunks';

import { IEventsStateExtras } from './types';

export const initialState = {
  events: [],
  fetch: {
    loading: false,
    error: false,
  },
  create: {
    loading: false,
    error: false,
  },
  cancel: {},
  update: {},
} as IEventsStateExtras;

export const slice = createSlice({
  name: 'events',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetch.pending, function (state) {
        state.fetch = {
          loading: true,
          error: false,
        };
      })
      .addCase(fetch.rejected, function (state) {
        state.fetch = {
          loading: false,
          error: true,
        };
      })
      .addCase(fetch.fulfilled, function (state, action) {
        const events = action.payload;
        state.fetch = {
          loading: false,
          error: false,
        };
        state.events = events;
      });

    builder
      .addCase(create.pending, function (state) {
        state.create = {
          loading: true,
          error: false,
        };
      })
      .addCase(create.rejected, function (state) {
        state.create = {
          loading: false,
          error: true,
        };
      })
      .addCase(create.fulfilled, function (state, action) {
        state.create = {
          loading: false,
          error: false,
        };
      });

    builder
      .addCase(cancel.pending, function (state, action) {
        const id = action.meta.arg;

        state.cancel[id] = {
          loading: true,
          error: false,
        };
      })
      .addCase(cancel.rejected, function (state, action) {
        const id = action.meta.arg;

        state.cancel[id] = {
          loading: false,
          error: true,
        };
      })
      .addCase(cancel.fulfilled, function (state, action) {
        const id = action.meta.arg;

        state.cancel[id] = {
          loading: false,
          error: false,
        };
        state.events = [...state.events].filter((event) => event.id !== id);
      });

    builder
      .addCase(update.pending, function (state, action) {
        const event = action.meta.arg;

        state.update[event.eventId as string] = {
          loading: true,
          error: false,
        };
      })
      .addCase(update.rejected, function (state, action) {
        const event = action.meta.arg;

        state.update[event.eventId as string] = {
          loading: false,
          error: true,
        };
      })
      .addCase(update.fulfilled, function (state, action) {
        const event = action.meta.arg;
        state.update[event.eventId as string] = {
          loading: false,
          error: false,
        };
      });
  },
});

import * as files from '@wix/social-groups-serverless/dist/files/http';

import { createAsyncThunk } from '../utils';

export const fetch = createAsyncThunk(
  'files:fetch',
  async function (groupId: string, thunkAPI) {
    const response = await thunkAPI.extra.httpClient.request(
      files.fetch({ groupId }),
    );

    return response.data;
  },
);

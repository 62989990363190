import { createStylesParam, StyleParamType } from '@wix/tpa-settings';

export const settings = {};

export const styles = {
  showReactions: createStylesParam('showReactions', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),

  showShareButton: createStylesParam('showShareButton', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),

  showCreatePost: createStylesParam('showCreatePost', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
};

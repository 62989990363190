import { createSlice } from '@reduxjs/toolkit';

import { initialize, completeStep, skipOnboarding } from './thunks';

import { stepsAdapter } from './adapter';

import {
  OnboardingStatus,
  StepKey,
  Status,
} from '@wix/ambassador-groups-onboarding-v1-onboarding/types';

import { IOnboardingState } from './types';

const initialState: IOnboardingState = {
  error: false,
  loading: false,
  data: {
    steps: stepsAdapter.getInitialState(),
    id: '',
    status: OnboardingStatus.INITIALIZING,
  },
};

export const slice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    closeOnboarding(state) {
      state.data.status = OnboardingStatus.COMPLETED;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initialize.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(initialize.rejected, (state) => {
        state.loading = false;
        state.error = true;
        state.data.status = OnboardingStatus.UNKNOWN_STATUS;
      })
      .addCase(initialize.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;

        state.data.id = action.payload?.id as string;
        state.data.status = action.payload?.status as OnboardingStatus;

        stepsAdapter.setAll(state.data.steps, action.payload?.steps || []);
      })
      .addCase(completeStep.fulfilled, (state, action) => {
        stepsAdapter.updateOne(state.data.steps, {
          id: action.payload.params.stepKey as StepKey,
          changes: { status: Status.COMPLETED },
        });
      })
      .addCase(skipOnboarding.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(skipOnboarding.fulfilled, (state, action) => {
        state.data.status = OnboardingStatus.COMPLETED;
        state.loading = false;
        state.error = false;
      })
      .addCase(skipOnboarding.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

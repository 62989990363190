import { curryRight } from 'lodash';
import { createSelector } from 'reselect';
import { Role } from '@wix/ambassador-social-groups-v2-group-member-with-profile/types';

import type { IRootState } from '../types';

import { selectCurrentUser } from 'store/application/selectors';
import { joinRequestsAdapter, membersAdapter, selectors } from './adapter';

export const selectMembers = (state: IRootState) => selectors.selectAll(state);

export const selectFollowStatus = curryRight(
  createSelector(
    [
      (state: IRootState) => state.members.status,
      (_: IRootState, memberId: string) => memberId,
    ],
    (status, memberId) => status.follow[memberId] || {},
  ),
  2,
);

export const selectMembersWithAdminRole = createSelector(
  selectMembers,
  (members) => members.filter((member) => member.role === Role.ADMIN),
);

export const selectIsLastAdmin = createSelector(
  selectMembersWithAdminRole,
  selectCurrentUser,
  (admins, user) => {
    const [last] = admins;

    return admins.length === 1 && last?.memberId === user?.memberId;
  },
);

export const selectChangeRoleStatus = curryRight(
  createSelector(
    [
      (state: IRootState) => state.members.status.changeRole,
      (state: IRootState, contactId: string) => contactId,
    ],
    (status, contactId) => status[contactId] || {},
  ),
  2,
);

export const selectMember = curryRight(
  createSelector(
    [(state: IRootState) => state, (_, memberId: string) => memberId],
    (state, memberId) => selectors.selectById(state, memberId),
  ),
  2,
);

export const selectJoinRequestStatus = curryRight(
  createSelector(
    [
      (state: IRootState) => state.members.requests.status,
      (state: IRootState, memberId: string) => memberId,
    ],
    (status, memberId) => ({
      approve: status.approve[memberId] || {},
      reject: status.reject[memberId] || {},
    }),
  ),
  2,
);

export const selectJoinRequests = (state: IRootState) => ({
  metadata: state.members.requests.metadata,
  requests: joinRequestsAdapter
    .getSelectors()
    .selectAll(state.members.requests),
});

export const selectRemoveStatus = curryRight(
  createSelector(
    [
      (state: IRootState) => state.members.status.remove,
      (state: IRootState, memberId: string) => memberId,
    ],
    (status, memberId) => status[memberId] || {},
  ),
  2,
);

export const selectAnswersStatus = curryRight(
  createSelector(
    [
      (state: IRootState) => state.members.status.answers,
      (state: IRootState, memberId: string) => memberId,
    ],
    (status, memberId) => status[memberId] || {},
  ),
  2,
);

export const selectMembersMetadata = (state: IRootState) => ({
  metadata: state.members.metadata,
  loading: state.members.status.fetch.loading,
  fetchMore: state.members.status.fetchMore.loading,
  error: state.members.status.fetch.error,
});

export const selectSuggestedMembers = (state: IRootState) =>
  membersAdapter.getSelectors().selectAll(state.members.suggested);

export const selectSuggestedMembersMetadata = (state: IRootState) => ({
  metadata: state.members.suggested.metadata,
  loading: state.members.suggested.status.fetch.loading,
  error: state.members.suggested.status.fetch.error,
});

export const selectInviteStatus = curryRight(
  createSelector(
    [
      (state: IRootState) => state.members.suggested.status.invite,
      (state: IRootState, memberId: string) => memberId,
    ],
    (status, memberId) => status[memberId] || {},
  ),
  2,
);

export const selectInviteStatuses = (state: IRootState) =>
  state.members.suggested.status.invite;

export const selectAddStatuses = (state: IRootState) =>
  state.members.suggested.status.add;

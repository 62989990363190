import { createSelector } from 'reselect';
import { curryRight, filter, sortBy } from 'lodash';

import {
  AllowPolicy,
  PrivacyStatus,
} from '@wix/ambassador-social-groups-v2-group/types';
import {
  MembershipStatus,
  Role,
} from '@wix/ambassador-social-groups-v2-group-member/types';
import { RequestStatus } from '@wix/ambassador-social-groups-v2-group-request/types';
import { NotificationChannel } from '@wix/ambassador-social-groups-v1-notification-settings/types';

import { selectIsSiteAdmin } from '../application/selectors';
import type { IRootState } from '../types';

import {
  EGroupsNamespaceType,
  MEMBER_LABELS,
  MEMBERS_ADD_WIDGET_LABELS,
  MEMBERS_WITH_COUNT_LABELS,
  MembersLabelMap,
  SEE_ALL_MEMBER_LABELS,
} from './constants';
import { selectors } from './adapter';
import { GroupApp, GroupAppKey, IGroup } from './types';

type IGroupAppParams = {
  groupId: string;
  application: GroupAppKey;
};

export const selectGroupStatus = curryRight(
  createSelector(
    [(state: IRootState) => state.groups, (_, groupId: string) => groupId],
    (state, groupId) => ({
      get: state.get[groupId] || {},
      update: state.update[groupId] || {},
      remove: state.remove[groupId] || {},
      membership: state.membership[groupId] || {},
      activity: state.activity[groupId] || {},
      rules: state.rules[groupId] || {},
      requirements: state.requirements[groupId] || {},
      questions: state.questions[groupId] || {},
      applications: state.applications[groupId] || {},
      notificationSettingsStatus: state.notificationSettings[groupId] || {},
      permissions: {},
    }),
  ),
  2,
);

export const selectGroupFetchStatus = curryRight(
  createSelector(
    [(state: IRootState) => state.groups, (_, slug: string) => slug],
    (state, slug) => state.get[slug] || {},
  ),
  2,
);

export const selectIsGroupUpdating = curryRight(
  createSelector(selectGroupStatus, (status) => status.update.loading),
  2,
);

export const selectIsGroupLoading = curryRight(
  createSelector(selectGroupStatus, (status) => status.get.loading),
  2,
);

export const selectAreQuestionsLoading = curryRight(
  createSelector(selectGroupStatus, (status) => status.questions.loading),
  2,
);

export const selectAreQuestionsUpdating = curryRight(
  createSelector(selectGroupStatus, (status) => status.questions.updating),
  2,
);

export const selectAreApplicationsUpdating = curryRight(
  createSelector(selectGroupStatus, (status) => status.applications.updating),
  2,
);

export const selectGroup = curryRight(
  createSelector(
    [(state: IRootState) => state, (_, groupId: string) => groupId],
    (state: IRootState, groupId) =>
      (selectors.selectById(state, groupId) || {}) as IGroup,
  ),
  2,
);

export const selectGroupCoverImage = createSelector(
  selectGroup,
  (group) => group.coverImage,
);

export const selectJoinedGroups = createSelector(
  (state: IRootState) => selectors.selectAll(state),
  (groups) =>
    groups.filter((group) => group.membership === MembershipStatus.JOINED),
);

export const selectGroupsNamespace = curryRight(
  createSelector(
    [
      (state: IRootState) => state.groups,
      (_, namespace: EGroupsNamespaceType) => namespace,
    ],
    (state, namespace) => state.namespaces[namespace],
  ),
  2,
);

export const selectGroupsMeta = curryRight(
  createSelector(selectGroupsNamespace, (namespace) => namespace.meta),
  2,
);

export const selectGroupsStatus = curryRight(
  createSelector(selectGroupsNamespace, (namespace) => namespace.statuses),
  2,
);

export const selectGroupList = curryRight(
  createSelector(
    (state: IRootState) => state,
    selectGroupsNamespace,
    (state, namespace) => namespace.ids.map((id) => selectGroup(state, id)),
  ),
  2,
);

export const selectGroups = curryRight(
  createSelector(
    [selectGroupList, selectGroupsMeta, selectGroupsStatus],
    (groups, meta, status) => ({
      groups,
      meta,
      status,
    }),
  ),
  2,
);

export const selectGroupsTotal = curryRight(
  createSelector(selectGroupsMeta, (meta) => meta.total),
  2,
);

export const selectGroupBySlug = curryRight(
  createSelector(
    [
      (state: IRootState) => selectors.selectAll(state),
      (_, slug: string) => slug,
    ],
    (groups, slug) =>
      groups.find((group) => group.slug === slug || group.id === slug) ||
      ({} as IGroup),
  ),
  2,
);

/**
 * Select groupId by slug
 */
export const selectGroupIdBySlug = curryRight(
  createSelector(selectGroupBySlug, (group) => group.id as string),
  2,
);

export const selectGroupUpdates = curryRight(
  createSelector(selectGroup, (group) => ({
    updates: group.updatesCount,
    members: group.pendingMembersCount,
  })),
  2,
);

export const selectGroupSlugById = curryRight(
  createSelector(selectGroup, (group) => group.slug as string),
  2,
);

export const selectGroupPrivacyStatus = curryRight(
  createSelector(selectGroup, (group) => group.privacyStatus),
  2,
);

export const selectIsGroupSecret = curryRight(
  createSelector(
    selectGroupPrivacyStatus,
    (privacyStatus) => privacyStatus === PrivacyStatus.SECRET,
  ),
  2,
);

export const selectGroupAuthorId = curryRight(
  createSelector(selectGroup, (group) => group.createdBy),
  2,
);

export const selectIsGroupPending = curryRight(
  createSelector(
    selectGroup,
    (group) => group.status === RequestStatus.PENDING,
  ),
  2,
);

export const selectIsGroupRejected = curryRight(
  createSelector(
    selectGroup,
    (group) => group.status === RequestStatus.REJECTED,
  ),
  2,
);

export const selectIsGroupPrivate = curryRight(
  createSelector(
    selectGroupPrivacyStatus,
    (privacyStatus) => privacyStatus === PrivacyStatus.PRIVATE,
  ),
  2,
);
export const selectIsGroupPublic = curryRight(
  createSelector(
    selectGroupPrivacyStatus,
    (privacyStatus) => privacyStatus === PrivacyStatus.PUBLIC,
  ),
  2,
);

export const selectGroupQuestions = curryRight(
  createSelector(selectGroup, (group) => group.questions || []),
  2,
);

export const selectAllApplications = curryRight(
  createSelector(selectGroup, (group) => {
    return sortBy(Object.values(group.applications || {}), 'tabOrderIndex');
  }),
  2,
);

export const selectInstalledApplications = curryRight(
  createSelector(selectAllApplications, (applications) => {
    return filter<GroupApp>(applications, 'installed');
  }),
  2,
);

export const selectApplication = curryRight(
  createSelector(
    [
      (state: IRootState, params: IGroupAppParams) =>
        selectGroup(state, params.groupId),
      (_, params: IGroupAppParams) => params.application,
    ],
    (group, application) => group.applications?.[application] as GroupApp,
  ),
  2,
);

export const selectHasAdminRole = curryRight(
  createSelector(selectGroup, (group) => group.role === Role.ADMIN),
  2,
);

export const selectGroupMembership = curryRight(
  createSelector(selectGroup, (group) => group.membership),
  2,
);

export const selectIsPendingGroupMember = curryRight(
  createSelector(
    selectGroup,
    (group) => group.membership === MembershipStatus.PENDING,
  ),
  2,
);

export const selectIsJoinedGroupMember = curryRight(
  createSelector(
    selectGroup,
    (group) => group.membership === MembershipStatus.JOINED,
  ),
  2,
);

export const selectIsUnknownGroupMember = curryRight(
  createSelector(
    selectGroup,
    (group) => group.membership === MembershipStatus.UNKNOWN_STATUS,
  ),
  2,
);

export const selectGroupRestriction = curryRight(
  createSelector(selectGroup, (group) => group.accessRestriction?.type),
  2,
);

const selectIsPermitted = curryRight(
  createSelector(
    selectGroup,
    selectIsSiteAdmin,
    selectIsJoinedGroupMember,
    (_: any, __: any, policy: AllowPolicy) => policy,
    (group, isSiteAdmin, isJoined, policy) => {
      const permissions: { [key in AllowPolicy]: Role[] } = {
        [AllowPolicy.ALL_MEMBERS]: [Role.MEMBER, Role.ADMIN],
        [AllowPolicy.OWNER_AND_ADMINS]: [Role.ADMIN],
        [AllowPolicy.OWNER]: [],
        [AllowPolicy.UNKNOWN]: [],
      };

      if (isSiteAdmin) {
        return true;
      }

      return isJoined && permissions[policy]?.includes(group.role);
    },
  ),
  3,
);

export const selectCanCreateEvents = curryRight(
  createSelector(
    (state: IRootState) => state,
    selectGroup,
    (state, group) =>
      selectIsPermitted(
        state,
        group.id,
        group.settings?.allowedToCreateEvents as AllowPolicy,
      ),
  ),
  2,
);

export const selectCanInviteMembers = curryRight(
  createSelector(
    (state: IRootState) => state,
    selectGroup,
    (state, group) => {
      return selectIsPermitted(
        state,
        group.id,
        group.settings?.allowedToInviteMembers as AllowPolicy,
      );
    },
  ),
  2,
);

export const selectCanAddMembers = curryRight(
  createSelector(
    (state: IRootState) => state,
    selectGroup,
    (state, group) =>
      selectIsPermitted(
        state,
        group.id,
        group.settings?.allowedToAddMembers as AllowPolicy,
      ),
  ),
  2,
);

export const selectCanApproveMembers = curryRight(
  createSelector(
    (state: IRootState) => state,
    selectGroup,
    (state, group) =>
      selectIsPermitted(
        state,
        group.id,
        group.settings?.allowedToApproveJoinRequests as AllowPolicy,
      ),
  ),
  2,
);

export const selectCanBrowse = curryRight(
  createSelector([selectGroup, selectIsSiteAdmin], (group, isSiteAdmin) => {
    const isJoined = group.membership === MembershipStatus.JOINED;

    switch (group.privacyStatus) {
      case PrivacyStatus.PRIVATE:
      case PrivacyStatus.SECRET:
        return isSiteAdmin || isJoined;

      case PrivacyStatus.PUBLIC:
        return true;

      case PrivacyStatus.UNKNOWN:
      default:
        return false;
    }
  }),
  2,
);

export const selectIsAppAvailable = curryRight(
  createSelector(
    [
      (state: IRootState, params: IGroupAppParams) =>
        selectGroup(state, params.groupId),
      (state: IRootState, params: IGroupAppParams) =>
        selectApplication(state, params),
      selectIsSiteAdmin,
    ],
    (group, application, isSiteAdmin) => {
      if (!group.id || !application) {
        return false;
      }
      const publicApplications = [GroupAppKey.ABOUT_APP];
      const isInstalled = application.installed!;
      const isJoined = group.membership === MembershipStatus.JOINED;

      if (publicApplications.includes(application.key as GroupAppKey)) {
        return isInstalled;
      }

      switch (group.privacyStatus) {
        case PrivacyStatus.PRIVATE:
        case PrivacyStatus.SECRET:
          return (isSiteAdmin || isJoined) && isInstalled;

        case PrivacyStatus.PUBLIC:
        default:
          return isInstalled;
      }
    },
  ),
  2,
);

export const selectMembersCount = curryRight(
  createSelector(selectGroup, (group) => group.membersCount),
  2,
);

function getMembersLabel(group: IGroup, labelsMap: MembersLabelMap) {
  const { memberTitle } = group;
  const key = memberTitle
    ? labelsMap[memberTitle as keyof typeof labelsMap]
    : null;

  const result = memberTitle ? key || memberTitle : labelsMap.Members;

  return {
    label: result,
    isCustom: memberTitle ? !key : false,
  };
}

export const selectMemberLabel = curryRight(
  createSelector(selectGroup, (group) => {
    return getMembersLabel(group, MEMBER_LABELS);
  }),
  2,
);

export const selectSeeAllMemberLabel = curryRight(
  createSelector(selectGroup, (group) => {
    return getMembersLabel(group, SEE_ALL_MEMBER_LABELS);
  }),
  2,
);

export const selectMembersAddWidgetLabel = curryRight(
  createSelector(selectGroup, (group) => {
    return getMembersLabel(group, MEMBERS_ADD_WIDGET_LABELS);
  }),
  2,
);

export const selectMembersWithCountLabel = curryRight(
  createSelector(selectGroup, (group) => {
    return getMembersLabel(group, MEMBERS_WITH_COUNT_LABELS);
  }),
  2,
);

export const selectGroupRules = curryRight(
  createSelector(selectGroup, (group) => group.rules),
  2,
);

export const selectGroupActivity = curryRight(
  createSelector(selectGroup, (group) => group.activity),
  2,
);

export const selectGroupName = curryRight(
  createSelector(selectGroup, (group) => group.name),
  2,
);

export const selectGroupFeedSettings = curryRight(
  createSelector(selectGroup, (group) => group.feedSettings),
  2,
);

export const selectGroupRequirements = curryRight(
  createSelector(selectGroup, (group) => group.requirements),
  2,
);

export const selectFuturePlans = curryRight(
  createSelector(selectGroupRequirements, (requirements) => {
    const { futurePlans } = requirements?.violation?.pricingPlansOptions || {};
    return futurePlans || [];
  }),
  2,
);

export const selectRequiredPricingPlanIds = curryRight(
  createSelector(selectGroupRequirements, (requirements) => {
    const { requiredPlans } =
      requirements?.violation?.pricingPlansOptions || {};
    if (requiredPlans) {
      return requiredPlans.map((plan) => plan.planId as string);
    }
    return [];
  }),
  2,
);

export const selectGroupSlug = curryRight(
  createSelector(selectGroup, (group) => group.slug),
  2,
);

export const selectEventsRestricted = curryRight(
  createSelector(selectGroupRequirements, (requirements) => {
    return requirements?.violation?.eventsOptions?.events || [];
  }),
  2,
);

export const selectCanSeeGroupActivity = curryRight(
  createSelector(
    selectIsJoinedGroupMember,
    selectIsGroupPublic,
    selectHasAdminRole,
    (isJoined, isPublic, isAdmin) => {
      return isJoined || isPublic || isAdmin;
    },
  ),
  2,
);

export const selectAllNotificationsSettings = curryRight(
  createSelector(selectGroup, (group) => group.notificationSettings || {}),
  2,
);

export const selectAreNotificationsSettingsUpdating = curryRight(
  createSelector(selectGroupStatus, (status) => {
    return Object.keys(status.notificationSettingsStatus).some((channel) => {
      return status.notificationSettingsStatus[channel as NotificationChannel]
        ?.updating;
    });
  }),
  2,
);

export const selectAreNotificationsSettingsLoading = curryRight(
  createSelector(selectGroupStatus, (status) => {
    return Object.keys(status.notificationSettingsStatus).some((channel) => {
      return status.notificationSettingsStatus[channel as NotificationChannel]
        ?.loading;
    });
  }),
  2,
);

export const selectGroupPermissions = curryRight(
  createSelector(selectGroup, (group) => group.permissions || {}),
  2,
);

export const selectFeedPermissionsByGroup = curryRight(
  createSelector(
    selectGroupPermissions,
    (permissions) => permissions.feed || {},
  ),
  2,
);

export const selectGroupCreateStatus = (state: IRootState) =>
  state.groups.create;

import type { IRootState } from '../types';
import { createSelector } from 'reselect';
import { partition, sortBy, curryRight } from 'lodash';

import { Status } from '@wix/ambassador-events-v3-event/types';
import { IExtendedEvent } from '@wix/social-groups-serverless/dist/events/types';

export const selectEvents = (state: IRootState) => state.events.events;

export const selectUpcomingAndPastEvents = createSelector(
  selectEvents,
  (events) => {
    const [upcoming, past] = splitByUpcomingAndPast(events);

    return [
      upcoming,
      sortBy(past, ['event', 'scheduling', 'config', 'startDate']),
    ];
  },
);

export const selectEventStatus = curryRight(
  createSelector(
    [(state: IRootState) => state.events, (_, eventId: string) => eventId],
    (state, eventId) => ({
      cancel: state.cancel[eventId] || {},
      update: state.update[eventId] || {},
    }),
  ),
  2,
);

export const selectEvent = curryRight(
  createSelector(
    [(state: IRootState) => state.events, (_, slug: string) => slug],
    (state, slug) => state.events.find((event) => event.slug === slug),
  ),
  2,
);

function splitByUpcomingAndPast(
  events: IExtendedEvent[],
): [upcoming: IExtendedEvent[], past: IExtendedEvent[]] {
  const [upcoming, past] = partition(
    events,
    (event) =>
      event.status === Status.STARTED || event.status === Status.UPCOMING,
  );

  return [upcoming, past];
}

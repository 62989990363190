import type { ControllerParams } from '@wix/yoshi-flow-editor';

import {
  getPricingPlansPublicAPI,
  NavigateToPackagePickerOptions,
} from '@wix/pricing-plans-tpa-api';

import type { IRootStore } from 'store/index';

export function PricingPlansVM(params: ControllerParams, store: IRootStore) {
  const { wixCodeApi } = params.controllerConfig;

  return {
    pricingPlans$: {
      navigateToPackagePicker,
    },
  };

  async function navigateToPackagePicker(
    options: NavigateToPackagePickerOptions,
  ) {
    try {
      const api = await getPricingPlansPublicAPI(wixCodeApi);

      api.navigateToPricingPage(options);
    } catch {}
  }
}

export type IPricingPlansVM = ReturnType<typeof PricingPlansVM>;

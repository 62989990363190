import { curryRight } from 'lodash';
import { createSelector } from 'reselect';

import type { IRootState } from '../types';
import { selectors } from './adapter';

export const selectGroupRequests = createSelector(
  (state: IRootState) => state,
  (state) => ({
    requests: selectors.selectAll(state),
    meta: state.groupRequests.meta,
  }),
);

export const selectGroupRequestsStatuses = createSelector(
  (state: IRootState) => state,
  (state) => state.groupRequests.statuses,
);

export const selectGroupRequestApproveStatus = curryRight(
  createSelector(
    [
      (state: IRootState) => state.groupRequests.statuses.approve,
      (_: IRootState, id: string) => id,
    ],
    (approveStatuses, id) => approveStatuses[id] || {},
  ),
  2,
);

import type { ControllerParams } from '@wix/yoshi-flow-editor';

import type { Resolvables } from './types';

/**
 * Warmup data helper
 * in CSR will pass SSR call result so you can determine weather or not to fetch data again
 */
export async function resolvables<T extends Resolvables>(
  params: ControllerParams,
  resolve: (result?: T) => Promise<T> | T,
) {
  const { compId } = params.controllerConfig;
  const { isSSR } = params.flowAPI.environment;
  const { window } = params.flowAPI.controllerConfig.wixCodeApi;

  const key = `${compId}-resolvables`;

  try {
    if (isSSR) {
      const result = await resolve();

      return window.warmupData.set(key, result);
    }

    await resolve(window.warmupData.get(key));
  } catch (err) {
    console.error(err);
  }
}

import type { ControllerParams } from '@wix/yoshi-flow-editor';

import { IGroupMembersEvent } from '@wix/social-groups-serverless/dist/events/types';

import * as events from 'store/events';
import type { IRootStore } from 'store/index';

export function EventsVM(params: ControllerParams, store: IRootStore) {
  return {
    events$: {
      fetch,
      create,
      cancel,
      update,
    },
  };

  function fetch(groupId: string) {
    return store.dispatch(events.thunks.fetch(groupId));
  }

  function create(params: IGroupMembersEvent) {
    return store.dispatch(events.thunks.create(params));
  }

  function cancel(eventId: string) {
    return store.dispatch(events.thunks.cancel(eventId));
  }

  function update(params: IGroupMembersEvent) {
    return store.dispatch(events.thunks.update(params));
  }
}

export type IEventsVM = ReturnType<typeof EventsVM>;

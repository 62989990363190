import type { ControllerParams } from '@wix/yoshi-flow-editor';

import * as topics from 'store/topics';
import type { IRootStore } from 'store/index';

export function TopicsVM(params: ControllerParams, store: IRootStore) {
  return {
    topics$: {
      create,
      fetch,
    },
  };

  function fetch(groupId: string) {
    return store.dispatch(topics.thunks.fetch(groupId));
  }

  function create(groupId: string, label: string) {
    return store.dispatch(
      topics.thunks.create({
        groupId,
        label,
      }),
    );
  }
}

export type ITopicsVM = ReturnType<typeof TopicsVM>;

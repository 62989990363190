import { ControllerParams } from '@wix/yoshi-flow-editor';

import type { Group } from '@wix/ambassador-social-groups-v2-group/types';

import * as groups from 'store/groups';
import type { IQueryGroupsParams } from 'store/groups/types';
import type { IRootStore } from 'store/index';

export function GroupsVM(params: ControllerParams, store: IRootStore) {
  return {
    groups$: {
      query,
      create,
    },
  };

  function query(params: IQueryGroupsParams) {
    return store.dispatch(groups.thunks.query(params));
  }

  function create(params: Group) {
    return store.dispatch(groups.thunks.create(params));
  }
}

export type IGroupsVM = ReturnType<typeof GroupsVM>;
